import { RouteRecordRaw } from 'vue-router'
import { getUserData, isUserLoggedIn } from '../utils/auth'
import { getHomeRouteForLoggedInUser } from '../utils/auth'

function getRootRoute() {
  try {
    const loggedIn = isUserLoggedIn();
    if (loggedIn) {
      return getHomeRouteForLoggedInUser();
    }
    return { name: 'login' };
  } catch (e) {
    return { name: 'login' };
  }
}

export const sharedRoutes: Array<RouteRecordRaw> = [
  { path: '/', name: 'root', redirect: getRootRoute() },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: () => import('../views/AccessDenied.vue')
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/NotFound.vue')
  }
]

export const sharedGuards = (to: any, from: any, next: any) => {
  // const middleware = to.meta?.middleware
  const loggedIn = isUserLoggedIn()
  // const currentUser = getUserData()

  const availableNotLoggedInRoutes = ['/login', '/forgot-password', '/reset-password']

  if (!loggedIn && !availableNotLoggedInRoutes.includes(to.path)) {
    return next({ name: 'login' })
  }

  if (loggedIn && to.name === 'login') {
    return next({ name: 'home' })
  }

  // if (middleware && middleware.every((m: any) => m.toLowerCase() !== currentUser.role.name.toLowerCase())) {
  //   return next({ name: 'AccessDenied' })
  // }

  return next()
};
