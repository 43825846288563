import { createApp } from 'vue'
import { createPinia } from "pinia"
import { createPersistedState } from "pinia-plugin-persistedstate"
import ElementPlus from "element-plus"
import * as ElementPlusIconsVue from "@element-plus/icons-vue"
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import VWave from 'v-wave'

import moment from 'moment'

import money from 'v-money3'

import VueGoogleMaps from '@fawmi/vue-google-maps'

import "balloon-css/balloon.min.css"
import "../../../node_modules/@mdi/font/css/materialdesignicons.min.css"
import "viewerjs/dist/viewer.css"
import VueViewer from "v-viewer"
// @ts-ignore: Unreachable code error
import vClickOutside from "v-click-outside"
// @ts-ignore
import VueHighlightJS from "vue3-highlightjs"
import "highlight.js/styles/solarized-light.css"
import mavonEditor from "mavon-editor"
// @ts-ignore
import VueChartkick from "vue-chartkick"
import "chartkick/chart.js"


import '@assets/scss/global.scss'
import '@super-admin-app/src/assets/scss/style.scss'
import 'flex.box/src/flexbox.css'
import "animate.css"

import axiosIns from '@super-admin-app/src/api/instance'
import { Model } from 'vue-api-query'
import {toDateTimeInTable} from "@shared/utils/dates";
Model.$http = axiosIns

const pinia = createPinia()
pinia.use(createPersistedState())

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(VueChartkick)
app.use(VueViewer)
app.use(vClickOutside)
app.use(mavonEditor)
app.use(VueHighlightJS)
app.use(ElementPlus)
app.use(money)
app.use(VWave, {})
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_PLACES_API_KEY,
    libraries: 'places'
  },
})


app.config.globalProperties.$filters = {
  dateInTable(date: string) {
    return toDateTimeInTable(date)
  },
  timeInTable(dateTime: string) {
    return moment(dateTime).format('HH:mm') // get24Time()
  },
  moneyInTable(money: number) {
    if (!money) {
      money = 0
    }

    return Number(money).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " $";
  }
}


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


app.mount("#app")
