import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["box-logo flex align-center", { 'nav-collapsed': _ctx.collapseNav, [_ctx.mode]: true }])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.logoComponent), {
      class: "image-logo",
      alt: "logo",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goto('/')))
    }))
  ], 2))
}