import axiosIns from '@super-admin-app/src/api/instance'
import { IAdminLoggedInUser } from '@super-admin-app/src/types/auth'
import { IForgotPassword, ISignIn } from '@shared/types/auth'

export const AuthApi = {
  async signIn(path: string, postData: ISignIn): Promise<IAdminLoggedInUser> {
    const { data } = await axiosIns.post(path, postData)
    return data
  },

  async signOut(path: string) {
    const { data } = await axiosIns.post(path)
    return data
  },

  async forgotPassword(path: string, postData: IForgotPassword): Promise<any> {
    const { data } = await axiosIns.post(path, postData)
    return data
  },

  async resetPassword(path: string, postData: any): Promise<any> {
    const { data } = await axiosIns.post(path, postData)
    return data
  }
}
