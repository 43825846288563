import axios from 'axios';
import {
  sharedRequestOnFulfilled,
  sharedRequestOnRejected,
  sharedResponseOnFulfilled,
  sharedResponseOnRejected
} from '@shared/api/config'

const axiosIns = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
  headers: {
    Accept: 'application/json'
  }
});

axiosIns.interceptors.request.use(sharedRequestOnFulfilled, sharedRequestOnRejected)

axiosIns.interceptors.response.use(sharedResponseOnFulfilled, sharedResponseOnRejected)

export default axiosIns
