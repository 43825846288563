export const navigation = [
  {
    groupTitle: 'Admins',
    items: [
      {
        icon: 'mdi-account-wrench',
        navTitle: 'Admins List',
        path: '/admins-list'
      }
    ]
  },
  {
    groupTitle: 'Hosts',
    items: [
      {
        icon: 'mdi-account-star',
        navTitle: 'Hosts List',
        path: '/hosts-list'
      }
    ]
  },
  {
    groupTitle: 'Events',
    items: [
      {
        icon: 'mdi-account-group',
        navTitle: 'Events List',
        path: '/events-list'
      },
      {
        icon: 'mdi-shape-plus',
        navTitle: 'Categories List',
        path: '/events-categories-list'
      }
    ]
  },
  {
    groupTitle: 'Businesses',
    items: [
      {
        icon: 'mdi-account-tie',
        navTitle: 'Businesses List',
        path: '/businesses-list'
      },
      {
        icon: 'mdi-advertisements',
        navTitle: 'Ads List',
        path: '/ads-list'
      }
    ]
  },
  {
    groupTitle: 'Attendees',
    items: [
      {
        icon: 'mdi-face-agent',
        navTitle: 'Attendees list',
        path: '/attendees-list'
      }
    ]
  },
  {
    groupTitle: 'Pricing',
    items: [
      {
        icon: 'mdi-currency-usd',
        navTitle: 'Pricing',
        path: '/pricing'
      },
      {
        icon: 'mdi-cash-multiple',
        navTitle: 'Transactions',
        path: '/transactions',
      }
    ]
  },
  {
    groupTitle: 'Logs',
    items: []
  }
]
