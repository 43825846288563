import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedApp = _resolveComponent("SharedApp")!

  return (_openBlock(), _createBlock(_component_SharedApp, {
    navigation: _ctx.superAdminAppNavigation,
    "logo-component": "SuperAdminAppLogo",
    onLogoutClick: _ctx.logoutClick
  }, null, 8, ["navigation", "onLogoutClick"]))
}