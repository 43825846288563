const CryptoJS = require('crypto-js')
const secretKey = 'cc_secret_temp'

export function isUserLoggedIn() {
  try {
    return !!getUserAccessToken()
  } catch (e) {
    return false
  }
}

export function getUserAccessToken() {
  return localStorage.getItem('accessToken')
}

export function getHomeRouteForLoggedInUser() {
  if (process.env.VUE_APP_APP_NAME === 'super-admin') {
    return { name: 'admins-list' }
  }
  return { name: 'home' }
}

export function frontendLogout() {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('userData')
}

export const encryptUserData = (userData: any) => CryptoJS.AES.encrypt(JSON.stringify(userData), secretKey)

export function commonSignIn(userData: any) {
  localStorage.setItem('accessToken', userData.access_token)
  localStorage.setItem('userData', encryptUserData(userData))
}

export const decryptUserData = (encrypted: any) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(encrypted, secretKey).toString(CryptoJS.enc.Utf8)
    return JSON.parse(decrypted)
  } catch (e) {
    frontendLogout()
  }
}

export const getUserData = () => (localStorage.getItem('userData')
  ? decryptUserData(localStorage.getItem('userData')) : null)
