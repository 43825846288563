import moment from "moment";

// to
export const toDayMonthYear = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format("D MMMM YYYY")
}

export const toDayMthYear = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format("D MMM YYYY")
}

export const toDayMonthYearTime = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format("MM/DD/yyyy h:mm A")
}

export const toTime = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format('h:mm A')
}

export const toDateTimeInTable = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format('MM/DD/YY hh:mm A')
}

export const toDateInTable = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format('MM/DD/YYYY')
}

export const toDatePeriod = (date?: string, separator: string = '/') => {
  if (!date) {
    return ''
  }

  const splited = date?.split(separator)

  if (splited.length < 1) {
    return `Invalid period`
  }

  const startDate = moment(splited[0], "YYYY-MM-DD").format("MM/DD/YYYY")
  const endDate = moment(splited[1], "YYYY-MM-DD").format("MM/DD/YYYY")

  return `${startDate} - ${endDate}`
}

// specific to
export const getMonthFromDate = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format('MMMM')
}

export const getDayMonthFromDate = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format('D MMMM')
}

export const getMthDayFromDate = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format('MMM D')
}

export const getISO = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format('YYYY-MM-DD')
}

export const getISOYearMonth = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format('YYYY-MM')
}

export const get24Time = (date: Date | string) => {
  const momentDate = moment(date)
  return momentDate.format('HH:mm')
}

export const getDaysCountOfPeriod = (date1: string, date2: string, includeStart: boolean = true) => {
  let count = moment(date2).diff(moment(date1), 'days')

  if (includeStart) {
    count += 1
  }

  return count
}
