<template>
  <img style="width: 200px; height: 100px;" src="@super-admin-app/src/assets/logo.svg">
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component({})
export default class SuperAdminAppLogo extends Vue{}
</script>

<style scoped>

</style>
