import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, Transition as _Transition, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "header"
}
const _hoisted_2 = {
  key: 2,
  class: "header"
}
const _hoisted_3 = { class: "main box grow flex column" }
const _hoisted_4 = {
  key: 0,
  class: "main-out-border--top-left"
}
const _hoisted_5 = {
  key: 1,
  class: "main-out-border--top-right"
}
const _hoisted_6 = {
  key: 2,
  class: "main-out-border--bottom-left"
}
const _hoisted_7 = {
  key: 3,
  class: "main-out-border--bottom-right"
}
const _hoisted_8 = {
  key: 0,
  class: "row font-weight-500 font-size-30"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vertical_nav = _resolveComponent("vertical-nav")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_horizontal_nav = _resolveComponent("horizontal-nav")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["layout-container flex justify-center", {
            column: _ctx.hasColumn,
            boxed: _ctx.boxed,
            'footer-above': _ctx.footerAbove,
            'content-only': !_ctx.navPos
        }])
  }, [
    (!_ctx.hideNavbar && _ctx.navPos === 'left')
      ? (_openBlock(), _createBlock(_component_vertical_nav, {
          key: 0,
          "navigation-items": _ctx.navigation,
          position: _ctx.navPos,
          "collapse-nav": _ctx.collapseNav,
          "app-logo": _ctx.logoComponent,
          "open-sidebar": _ctx.openSidebar,
          "onUpdate:openSidebar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openSidebar) = $event)),
          onCollapseNavToggle: _cache[1] || (_cache[1] = ($event: any) => (_ctx.collapseNav = !_ctx.collapseNav)),
          onPushPage: _ctx.closeSidebar,
          onLogoutClick: _ctx.logoutClick
        }, null, 8, ["navigation-items", "position", "collapse-nav", "app-logo", "open-sidebar", "onPushPage", "onLogoutClick"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex column box grow", {
        'container': !_ctx.hideNavbar
      }])
    }, [
      (!_ctx.hideNavbar && _ctx.toolbar === 'top')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Toolbar, {
              onToggleSidebar: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openSidebar = !_ctx.openSidebar)),
              "menu-burger": _ctx.navPos
            }, null, 8, ["menu-burger"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.hideNavbar && _ctx.navPos === 'top')
        ? (_openBlock(), _createBlock(_component_horizontal_nav, {
            key: 1,
            "nav-items": _ctx.navigation,
            position: _ctx.navPos,
            onPushPage: _ctx.closeSidebar,
            onLogoutClick: _ctx.logoutClick
          }, null, 8, ["nav-items", "position", "onPushPage", "onLogoutClick"]))
        : _createCommentVNode("", true),
      (_ctx.toolbar === 'bottom')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Toolbar, {
              onToggleSidebar: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openSidebar = !_ctx.openSidebar)),
              "menu-burger": _ctx.navPos
            }, null, 8, ["menu-burger"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.roundedCorners)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.roundedCorners)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.roundedCorners)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6))
          : _createCommentVNode("", true),
        (_ctx.roundedCorners)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view, { class: "view box grow" }, {
          default: _withCtx(({ Component }) => [
            (!_ctx.hidePageTitle)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.getPageTitle), 1))
              : _createCommentVNode("", true),
            _createVNode(_Transition, {
              name: _ctx.viewAnimation,
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_ctx.hideNavbar === false)
                  ? (_openBlock(), _createBlock(_component_el_scrollbar, { key: 0 }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                      ]),
                      _: 2
                    }, 1024))
                  : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 1 }))
              ]),
              _: 2
            }, 1032, ["name"])
          ]),
          _: 1
        })
      ]),
      (_ctx.footer === 'below')
        ? (_openBlock(), _createBlock(_component_Footer, {
            key: 3,
            position: _ctx.footer
          }, null, 8, ["position"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.footer === 'above')
      ? (_openBlock(), _createBlock(_component_Footer, {
          key: 1,
          position: _ctx.footer
        }, null, 8, ["position"]))
      : _createCommentVNode("", true)
  ], 2))
}