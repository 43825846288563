<template>
    <div class="horizontal-nav flex" :class="{ ['pos-' + position]: true }">
        <logo mode="horizontal" />
        <div class="box grow flex nav-container">
            <el-scrollbar class="box grow">
                <Nav :navigation-items="navItems"
                     @push-page="pushPage"
                     mode="horizontal"
                />
            </el-scrollbar>
          <div class="flex align-center justify-center" style="width: 4rem;">
            <span class="mdi mdi-logout" @click="logoutClick"></span>
          </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core"
import Nav from "@shared/components/core/navigation/nav.vue"
import Logo from "@shared/components/core/logo.vue"

export default defineComponent({
    name: "HorizontalNav",
    props: ["position", "navItems"],
    emits: ['logout-click', 'push-page'],
    data() {
        return {}
    },
    methods: {
      logoutClick() {
        this.$emit('logout-click')
      },
      pushPage(index: any, indexPath: any) {
          this.$emit("push-page", { page: index })
      }
    },
    components: {
        Nav,
        Logo
    }
})
</script>

<style lang="scss" scoped>
@import "@shared/assets/scss/_variables.scss";

.horizontal-nav {
    overflow: hidden;
    //overflow-x: scroll;
    //-webkit-overflow-scrolling: touch;
    //-ms-overflow-style: -ms-autohiding-scrollbar;
    background: white;
    height: 45px;
    z-index: 1;
    margin-bottom: 30px;

    &.pos-top {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }
    &.pos-bottom {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .nav-container {
        position: relative;
        overflow: hidden;
        //padding-right: 20px;

        &::before {
            content: "";
            display: block;
            z-index: 1;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 0px;
            height: 20px;
            //box-shadow: 0px 0px 15px 15px #fff;
        }
        &::after {
            content: "";
            display: block;
            z-index: 1;
            position: absolute;
            top: 0px;
            right: 20px;
            width: 0px;
            height: 20px;
            //box-shadow: 0px 0px 15px 15px #fff;
        }
    }
}
</style>
