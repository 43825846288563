import { useMainStore } from '../stores/main'
import { AxiosResponse } from 'axios'
import { frontendLogout } from '../utils/auth'

export const apiErrorResponseStatuses = ['validation_error', 'error', 'db_error', 'sort_error', 'not_found'];

export const sharedResponseOnFulfilled = (response: AxiosResponse) => {
  const contentType = response.headers['content-type']
  if (contentType !== 'application/json') {
    return { data: response.data, contentType}
  }
  const { message } = response.data
  if (message) {
    (useMainStore()).tSuccess(message)
  }
  return response.data
}

export const sharedResponseOnRejected = async (error: any) => {
  const { data: { message, errors }, status } = error.response
  if (status === 404) {
    (useMainStore()).tError('API method not found')
  } else if (status === 422) {
    errors.forEach((eMessage: string) => {
      (useMainStore()).tError(eMessage)
    })
  } else if (message) {
    (useMainStore()).tError(message)
  }

  if (status === 401) {
    frontendLogout();
    window.location.href = '/'
  }

  console.log(error)

  throw new Error(error.response)
}

export const sharedRequestOnFulfilled = (config: any) => {
  const token = localStorage.getItem('accessToken')
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

export const sharedRequestOnRejected = (error: any) => console.log(error)
