<template>
  <div
    class="layout-container flex justify-center"
    :class="{
            column: hasColumn,
            boxed: boxed,
            'footer-above': footerAbove,
            'content-only': !navPos
        }"
  >
<!--    <transition name="fade">-->
<!--      <div class="splash-screen" v-if="splashScreen">-->
<!--        <div class="wrap">-->
<!--          <img src="/logo_old.svg" class="logo" alt="logo" />-->
<!--          <img src="/Ripple-2s-200px.gif" alt="loading-image" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </transition>-->
    <vertical-nav
      v-if="!hideNavbar && navPos === 'left'"
      :navigation-items="navigation"
      :position="navPos"
      :collapse-nav="collapseNav"
      :app-logo="logoComponent"
      v-model:open-sidebar="openSidebar"
      @collapse-nav-toggle="collapseNav = !collapseNav"
      @push-page="closeSidebar"
      @logout-click="logoutClick"
    />

    <div
      class="flex column box grow"
      :class="{
        'container': !hideNavbar
      }"
    >
      <div class="header" v-if="!hideNavbar && toolbar === 'top'">
        <Toolbar @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="navPos" />
      </div>
      <horizontal-nav
        :nav-items="navigation"
        :position="navPos"
        @push-page="closeSidebar"
        @logout-click="logoutClick"
        v-if="!hideNavbar && navPos === 'top'"
      />
      <div class="header" v-if="toolbar === 'bottom'">
        <Toolbar @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="navPos" />
      </div>

      <div class="main box grow flex column">
        <span class="main-out-border--top-left" v-if="roundedCorners"></span>
        <span class="main-out-border--top-right" v-if="roundedCorners"></span>
        <span class="main-out-border--bottom-left" v-if="roundedCorners"></span>
        <span class="main-out-border--bottom-right" v-if="roundedCorners"></span>
        <router-view v-slot="{ Component }" class="view box grow">
          <div v-if="!hidePageTitle" class="row font-weight-500 font-size-30">
            {{ getPageTitle }}
          </div>
          <transition :name="viewAnimation" mode="out-in">
            <el-scrollbar v-if="hideNavbar === false">
              <component :is="Component" />
            </el-scrollbar>
            <component :is="Component" v-else />
          </transition>
        </router-view>
      </div>

      <Footer v-if="footer === 'below'" :position="footer" />
    </div>

    <Footer v-if="footer === 'above'" :position="footer" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core'
import { detect } from 'detect-browser'
import HorizontalNav from '@shared/components/core/navigation/horizontal-nav.vue'
import VerticalNav from '@shared/components/core/navigation/vertical-nav.vue'
import Toolbar from '@shared/components/core/toolbar.vue'
import Footer from '@shared/components/core/footer.vue'
import { useMainStore } from '@shared/stores/main'
import { EFooter, ENavPos } from '@shared/types'
const browser = detect()
import { useRoute } from 'vue-router'
import { navigation } from '@super-admin-app/src/navigation'

export default defineComponent({
  name: 'App',
  data () {
    return {
      collapseNav: false,
      openSidebar: false,
      innerWidth: 0,
      hideNavbarRoutes: ['login', 'forgot-password', 'reset-password'],
      hidePageTitleRoutes: ['login', 'forgot-password', 'reset-password']
    }
  },
  emits: ['logout-click'],
  props: ['navigation', 'logoComponent'],
  computed: {
    route() {
      return useRoute()
    },
    currentRouteName () {
      return String(this.route?.name)
    },
    hideNavbar () {
      return this.hideNavbarRoutes.includes(this.currentRouteName)
    },
    hidePageTitle () {
      return this.hidePageTitleRoutes.includes(this.currentRouteName)
    },
    navPos () {
      if (
        this.innerWidth <= 768) {
        return 'top'
      }
      return useMainStore().navPos
    },
    toolbar () {
      return 'top'
    },
    footer () {
      return useMainStore().footer
    },
    boxed () {
      return useMainStore().boxed
    },
    roundedCorners () {
      return useMainStore().roundedCorners
    },
    viewAnimation () {
      return useMainStore().viewAnimation || 'none'
    },
    isLogged () {
      return useMainStore().isLogged
    },
    splashScreen () {
      return useMainStore().splashScreen
    },
    footerAbove () {
      return useMainStore().footer === EFooter.above
    },
    hasColumn () {
      return useMainStore().navPos === ENavPos.top || useMainStore().navPos === ENavPos.bottom
    },
    getNavigation() {
      return navigation
    },
    getPageTitle() {
      const currentRoutePath = (useRoute()).path
      return this.findTitleByPath(this.getNavigation, currentRoutePath)
    }
  },
  methods: {
    findTitleByPath(menuItems: any, currentPath: any) {
      for (const group of menuItems) {
        for (const item of group.items) {
          if (item.path === currentPath) {
            return item.title || ''
          }
        }
      }
      return ''
    },
    logoutClick() {
      this.$emit('logout-click')
    },
    resizeOpenNav () {
      this.innerWidth = window.innerWidth
      if (window.innerWidth <= 768) {
        this.collapseNav = false
      }
    },
    closeSidebar () {
      this.openSidebar = false
    }
  },
  components: {
    HorizontalNav,
    VerticalNav,
    Toolbar,
    Footer,
    // LayoutPicker
  },
  created () {
    if (browser && browser.name) document.getElementsByTagName('html')[0].classList.add(browser.name)
  },
  mounted () {
    this.resizeOpenNav()
    window.addEventListener('resize', this.resizeOpenNav)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.resizeOpenNav)
  }
})
</script>

<style lang="scss">
@import "@shared/assets/scss/_variables";
@import "@shared/assets/scss/_mixins";

.el-menu-item-group__title {
  color: $text-color-primary !important;
  font-weight: 900;
}

.layout-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: $background-color;

  .container {
    overflow: hidden;

    .header {
      height: 60px;
      margin-bottom: 20px;
      margin-top: 10px;
      margin-left: 30px;
      margin-right: 30px;
    }

    .main {
      position: relative;
      overflow: hidden;
      padding: 0 30px;
    }

    .view {
      box-sizing: border-box;
      transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
      backface-visibility: hidden;
      /*-webkit-perspective: 1000px;*/

      & > .el-scrollbar__wrap {
        padding: 0 20px;
      }
    }
    .fade-right-enter-from {
      opacity: 0;
      transform: translate(-30px, 0);
    }
    .fade-right-leave-to {
      opacity: 0;
      transform: translate(30px, 0);
    }
    .fade-left-enter-from {
      opacity: 0;
      transform: translate(30px, 0);
    }
    .fade-left-leave-to {
      opacity: 0;
      transform: translate(-30px, 0);
    }
    .fade-top-enter-from {
      opacity: 0;
      transform: translate(0, 30px);
    }
    .fade-top-leave-to {
      opacity: 0;
      transform: translate(0, -30px);
    }
    .fade-top-in-out-enter-from,
    .fade-top-in-out-leave-to {
      opacity: 0;
      transform: translate(0, 30px);
    }
    .fade-bottom-enter-from {
      opacity: 0;
      transform: translate(0, -30px);
    }
    .fade-bottom-leave-to {
      opacity: 0;
      transform: translate(0, 30px);
    }
    .fade-bottom-in-out-enter-from,
    .fade-bottom-in-out-leave-to {
      opacity: 0;
      transform: translate(0, -30px);
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }

    .main-out-border {
      &--top-left,
      &--top-right {
        background: linear-gradient($background-color, rgba(230, 235, 241, 0));
        height: 16px;
        position: absolute;
        width: 8px;
        z-index: 1;
        top: 4px;
      }
      &--bottom-left,
      &--bottom-right {
        background: linear-gradient(rgba(230, 235, 241, 0), $background-color);
        height: 16px;
        position: absolute;
        width: 8px;
        z-index: 1;
        bottom: 4px;
      }

      &--top-left,
      &--bottom-left {
        left: 42px;

        &::after {
          content: "";
          height: 5px;
          position: absolute;
          right: -4px;
          top: -4px;
          width: 12px;
          box-shadow: 8px 0px 0px 0px $background-color inset;
          border-top-left-radius: 5px;
        }
      }
      &--top-right,
      &--bottom-right {
        right: 42px;

        &::after {
          content: "";
          height: 5px;
          left: -4px;
          position: absolute;
          top: -4px;
          width: 12px;
          box-shadow: -8px 0px 0px 0px $background-color inset;
          border-top-right-radius: 5px;
        }
      }

      &--bottom-left:after {
        border-radius: 0;
        border-bottom-left-radius: 5px;
      }
      &--bottom-right:after {
        border-radius: 0;
        border-bottom-right-radius: 5px;
      }

      &--bottom-left,
      &--bottom-right {
        &::after {
          top: initial;
          bottom: -4px;
        }
      }
    }
  }

  &.boxed {
    max-width: 1300px;
    margin: 0 auto;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }

  &.footer-above {
    padding-bottom: 40px;
    position: relative;
  }

  &.content-only {
    .container {
      .main-out-border--top-left,
      .main-out-border--top-right,
      .main-out-border--bottom-left,
      .main-out-border--bottom-right {
        display: none;
      }
    }
  }
}

html:not(.ie) {
  .layout-container {
    .container {
      max-width: 1920px;
    }
  }
}

@media (min-width: 1920px) {
  .layout-container:not(.boxed) {
    &.column {
      .container {
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .layout-container {
    .container {
      /*width: 100%;
max-width: 100%;
height: 100%;
max-height: 100%;*/

      .header {
        height: 50px;
        background: #fff;
        box-shadow: 0px -20px 10px 20px rgba(0, 0, 0, 0.25);
        margin: 0;
        margin-bottom: 5px;

        .toggle-sidebar {
          box-shadow: none !important;
        }

        .search-box {
          & > .el-autocomplete {
            box-shadow: none !important;
          }
        }
      }

      .main {
        padding: 0 5px;
      }

      .view {
        max-width: 100%;

        & > .el-scrollbar__wrap {
          padding: 0 15px;
          padding-top: 15px;
        }
      }
      .main-out-border--top-left,
      .main-out-border--top-right,
      .main-out-border--bottom-left,
      .main-out-border--bottom-right {
        display: none;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
